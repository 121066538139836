import React from "react"
import { Link, graphql } from "gatsby"
import cheerio from "cheerio"

import { Container, Sidebar, ShareSns } from "../../components"
import { PostWrapper } from "../../elements"
import Seo from "../../components/seo"

const SinglePost = ({ data }) => {
  const $ = cheerio.load(data.microcmsBlog.content)
  $(`content`).html() // fix for cheerio v1
  const headings = $("h2, h3").toArray()
  const toc = headings.map(data => ({
    text: data.children[0].data,
    id: data.attribs.id,
    name: data.name,
  }))

  return (
    <Container>
      <Seo
        title={data.microcmsBlog.title}
        stylesheet={data.css.publicURL}
        description={data.microcmsBlog.excerpt}
        image={data.microcmsBlog.featureImage.url}
      />
      <PostWrapper>
        {/* 記事タイトル */}
        <div className="mb-6 lg:mb-8">
          <div className="flex items-center mb-4 md:mb-6">
            {/* 日付 */}
            <span className="text-2xs md:text-xs">
              {data.microcmsBlog.date}
            </span>
            {/* カテゴリー */}
            <Link
              className="
              flex
              items-center
              justify-center
              ml-4
              px-4
              py-1
              no-underline
              not-italic
              bg-gray-100
              rounded-full
            "
              to={`/${data.microcmsBlog.category[0].id}`}
            >
              <span className="text-2xs font-medium md:text-xs">
                {data.microcmsBlog.category[0].name}
              </span>
            </Link>
          </div>
          <div className="flex gap-x-1 items-center mb-4 md:mb-6">
            <div className="w-1.5 h-1.5 bg-black"></div>
            <div className="w-1.5 h-1.5 bg-black"></div>
            <div className="w-1.5 h-1.5 bg-black"></div>
            <div className="w-1.5 h-1.5 bg-black"></div>
          </div>
          {/* タイトル */}
          <div className="pb-4 border-b border-black md:pb-6 lg:p-0 lg:border-none">
            <h1 className="text-xl font-bold md:text-2xl">
              {data.microcmsBlog.title}
            </h1>
          </div>
        </div>
        <div className="grid gap-x-12 grid-cols-3">
          {/* 記事内容 */}
          <div className="col-span-full lg:col-span-2">
            {/* SNSシェア */}
            {typeof window !== "undefined" && window.location.href && (
              <ShareSns
                articleUrl={window.location.href}
                articleTitle={data.microcmsBlog.title}
              />
            )}
            {/* サムネイル */}
            <div className="mb-4 md:mb-8">
              <div className="md:h-112 w-full h-64">
                <img
                  className="w-full h-full object-cover"
                  src={data.microcmsBlog.featureImage.url}
                  alt={`${data.microcmsBlog.title} 画像`}
                />
              </div>
            </div>
            {/* 記事本文 */}
            <div className="mb-16 md:mb-24">
              <div className="flex items-center mb-4">
                <img
                  className="mr-2 my-auto w-3.5 h-full"
                  src={data.bullet.publicURL}
                  alt="ポイント"
                />
                <h6>本記事でわかること</h6>
              </div>
              <ul>
                <li className="flex items-center mb-2">
                  <h6>{data.microcmsBlog.point1}</h6>
                </li>
                <li className="flex items-center mb-2">
                  <h6>{data.microcmsBlog.point2}</h6>
                </li>
                <li className="flex items-center">
                  <h6>{data.microcmsBlog.point3}</h6>
                </li>
              </ul>
              {/* あらすじ */}
              <p
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: `${data.microcmsBlog.outline}`,
                }}
              ></p>
              <ol
                id="tableOfContents"
                className="mb-6 p-4 bg-white border border-black md:p-6"
              >
                {toc.map(toc => {
                  if (toc.name === "h2") {
                    return (
                      <li
                        className="tob flex items-center justify-between mb-2 ml-0 tob"
                        key={toc.id}
                      >
                        <span
                          className="
                              flex
                              items-center
                              justify-center
                              w-2.5
                              h-2.5
                              text-white text-sm
                              bg-black
                              transform rotate-45
                            "
                        ></span>
                        <Link
                          className={`${toc.name} ml-2 relative no-underline text-sm not-italic w-11/12 md:w-full`}
                          to={`#${toc.id}`}
                        >
                          {toc.text}
                        </Link>
                      </li>
                    )
                  } else if (toc.name === "h3") {
                    return (
                      <li
                        className="flex items-center mb-2 ml-10 tob"
                        key={toc.id}
                      >
                        <span>
                          <img
                            className="w-full h-full my-auto"
                            src={data.chevron.publicURL}
                            alt=""
                          />
                        </span>
                        <Link
                          className={`${toc.name} ml-2 relative no-underline text-sm not-italic`}
                          to={`#${toc.id}`}
                        >
                          {toc.text}
                        </Link>
                      </li>
                    )
                  } else {
                    return
                  }
                })}
              </ol>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${data.microcmsBlog.content}`,
                }}
              ></div>
            </div>
            {/* SNSシェア */}
            {typeof window !== "undefined" && window.location.href && (
              <ShareSns
                articleUrl={window.location.href}
                articleTitle={data.microcmsBlog.title}
              />
            )}
          </div>
          <div className="hidden gap-2 p-2 border-2 border-blue-300 group-hover:opacity-80 hover:opacity-80 group bg-red-700 bg-yellow-500 bg-red-500 flex-wrap md:flex-nowrap "></div>
          <Sidebar />
        </div>
      </PostWrapper>
    </Container>
  )
}

export const query = graphql`
  query($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      content
      blogId
      featureImage {
        url
      }
      date(formatString: "Y-MM-DD")
      category {
        name
        id
      }
      title
      point1
      point2
      point3
      outline
      excerpt
    }
    bullet: file(relativePath: { eq: "bullet.svg" }) {
      publicURL
    }
    check: file(relativePath: { eq: "check.svg" }) {
      publicURL
    }
    chevron: file(relativePath: { eq: "chevron.svg" }) {
      publicURL
    }
    css: file(relativePath: { eq: "post.css" }) {
      publicURL
    }
  }
`

export default SinglePost
